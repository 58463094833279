/* eslint-disable import/namespace */
'use client'

import { useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from '@mui/material'
import { Button } from '@/components/library/button'

import * as Sentry from '@sentry/nextjs'

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    Sentry.captureException(error)
  }, [error])

  return (
    <Dialog color='error' scroll='paper' open>
      <DialogTitle>
        OH NO! There is a problem please click below to refresh the page.
      </DialogTitle>
      {process.env.NODE_ENV === 'development' ? (
        <DialogContent dividers>
          <DialogContentText id='scroll-dialog-description' tabIndex={-1}>
            {error.message}
          </DialogContentText>
          <DialogContentText id='scroll-dialog-description' tabIndex={-1}>
            {error.stack}
          </DialogContentText>
        </DialogContent>
      ) : (
        ''
      )}
      <DialogActions>
        <Button
          variant='text'
          onClick={() => {
            // window.location.reload()
            reset()
          }}
        >
          Reload
        </Button>
      </DialogActions>
    </Dialog>
  )
}
